<nav class="navbar sticky-top navbar-expand-lg navbar-dark w-100" style="background-color: #020932; ">
  <div class="container-fluid d-flex justify-content-between">
    <a class="navbar-brand  " href="#"><img src="assets\img\fenixLogoWithTextInWhite.png" width="200px" height="70px"></a>

    <button class="navbar-toggler btn " type="button" data-bs-toggle="collapse" (click)="isMenuCollapsed = !isMenuCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse w-100" id="navbarSupportedContent" [ngbCollapse]="isMenuCollapsed">
      <div class="d-flex justify-content-lg-end w-100">
        <ul class="h5 navbar-nav nav-list" style="font-family: popins;">

          <li class="nav-list-item">
            <a class="nav-link text-white " routerLinkActive="is-active"  routerLink="/home" [routerLinkActiveOptions]="{exact:true}">Home</a>
          </li>
          <li class="nav-list-item">
            <a class="nav-link text-white " routerLinkActive="is-active"  routerLink="/about">About Us</a>
          </li>
          <li class="nav-list-item">
            <a class="nav-link text-white" routerLinkActive="is-active"  routerLink="/visas">Visa Information</a>
          </li>
          <!-- <li class="nav-list-item">
            <a class="nav-link text-white" routerLinkActive="is-active"  routerLink="/visaGallery">Gallery</a>
          </li> -->
          <li class="nav-list-item">
            <div class="dropdown" style="border-color: white;">
              <a class="nav-link text-white" data-bs-toggle="dropdown" routerLinkActive="is-active"> Gallery </a>
              <div class="dropdown-menu " style="background-color: #020932;">
                <a class="dropdown-item text-white h5" style="background-color: #020932;" routerLink="/Gallery/instituteGallery" routerLinkActive="is-active">Institute</a>
                <a class="dropdown-item text-white h5" style="background-color: #020932;" routerLink="/Gallery/visaGallery" routerLinkActive="is-active">Visa</a>
                <a class="dropdown-item text-white h5" style="background-color: #020932;" routerLink="/Gallery/resultGallery" routerLinkActive="is-active">Results</a>
              </div>
            </div>
          </li>
          <li class="nav-list-item">
            <a class="nav-link text-white" routerLinkActive="is-active" routerLink="/services">Our Services</a>
          </li>
          <li class="nav-list-item">
            <a class="nav-link text-white" routerLinkActive="is-active"  routerLink="/contact">Contact Us</a>
          </li>
  
        </ul>

      </div>
    </div>
  </div>
</nav>