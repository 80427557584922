<div class="container-fluid  p-0 ">
  <div class="bg-image px-3 py-4"
  style="background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 10%, transparent)0% 0% ,  
  url('https://educationusa.state.gov/sites/default/files/styles/carousel-full-bleed_1220x505/public/homepage_carousel_images_09.28.2021.png?itok=YaKjNTSv' ) no-repeat; background-size: cover; ">    
  <div class="row px-lg-4 px-sm-2 py-3 ">
      <div class="col-12 col-md-8 mt-sm-5 ">
        <div class="text-white px-3 ms-lg-5 mt-3 animated fadeInLeft">
          <h1>You Dream, </h1>
          <h1>We Accomplish</h1>
          <p>Consult with Fenix and get the best possible solutions for you to fulfill your dreams.</p>
          <br>
          <a routerLink="/contact" class=" btn btn-info-gradiant text-white"> APPLY NOW </a>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-3">
        <div class="card animated fadeInRight" style="background: rgba(217, 217, 217, 0.8);">
          <div class="card-header border-0 ">
            <h4>Enquire Now</h4>
          </div>

          <form [formGroup]="enquiryForm" (ngSubmit)="onSubmitEnquiry()">
            <div class="card-body">       
              <div class="mb-3">
                <label for="name" class="form-label" >Name</label>
                <input type="text" name="name" formControlName="name"  class="form-control" id="name" placeholder="Name"  >
                <div class="text-danger mt-1" style="font-size: 12px;" *ngIf="enquiryForm.get('name')?.invalid && ( enquiryForm.get('name')?.dirty || enquiryForm.get('name')?.touched )">
                  <div *ngIf="enquiryForm.get('name')?.hasError('required')">Name is Required</div>
                  <div *ngIf="enquiryForm.get('name')?.hasError('pattern')">Only Alphabets</div>
                </div>
              </div>

              <div class="mb-3">
                <label for="email" class="form-label" >Email</label>
                <input type="email" name="email" formControlName="email" class="form-control" id="email" placeholder="Email"  >
                <div class="text-danger mt-1" style="font-size: 12px;" *ngIf="enquiryForm.get('email')?.invalid && ( enquiryForm.get('email')?.dirty || enquiryForm.get('email')?.touched )">
                  <div *ngIf="enquiryForm.get('email')?.hasError('required')">Email is Required</div>
                  <div *ngIf="enquiryForm.get('email')?.hasError('email')">Email is badly formatted</div>
                </div>
              </div>

              <div class="mb-3">
                <label for="phone" class="form-label" >Contact No</label>
                <input type="phone" name="phone" formControlName="phone" class="form-control" id="phone" placeholder="Contact No"  >
                <div class="text-danger mt-1" style="font-size: 12px;" *ngIf="enquiryForm.get('phone')?.invalid && ( enquiryForm.get('phone')?.dirty || enquiryForm.get('phone')?.touched )">
                  <div *ngIf="enquiryForm.get('phone')?.hasError('required')">Phone is Required</div>
                  <div *ngIf="enquiryForm.get('phone')?.hasError('pattern')  || enquiryForm.get('phone')?.hasError('maxlength') || enquiryForm.get('phone')?.hasError('minlength')">Enter 10 digits valid phone number
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label for="query" class="form-label">Query</label>
                  <textarea class="form-control" id="query" rows="2" formControlName="query" placeholder="Any Query?"  ></textarea>
                  <div class="text-danger mt-1" style="font-size: 12px;" *ngIf="enquiryForm.get('query')?.invalid && ( enquiryForm.get('query')?.dirty || enquiryForm.get('query')?.touched )">
                  <div *ngIf="enquiryForm.get('query')?.hasError('required')">Query is Required</div>
                </div>
              </div>

              <div class="text-end">
                <button type="submit" class=" btn btn-outline-primary" >Submit</button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid ">
  <marquee><p style="font-family: Impact; font-size: 18pt">"Unlock your global opportunities with Fenix Immigration Services! We are your trusted partner in achieving your dreams of studying abroad"</p></marquee>
</div>


<div class="container mt-5">
  <div class="text-center my-5">
    <h1 style="font-family: 'Franklin Gothic Medium';">STUDY VISA</h1>
    <h6 class="text-muted"> Choose from vast Countries to settle in Abroad</h6>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let visas of visaList; index as idx">
      <div class="card card-1 animated fadeInUp">
        <img loading="eager" [src]="visas.imageUrl || '/assets/img/empty-profile.png'" class="card-img-top" style="width:100%; height: 270px">
        <div class="card-body">
          <h5 class="card-title">{{visas.title}}</h5>
          <p class="card-text">{{visas.description}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center my-4">
    <a routerLink="/visas" class="btn btn-info-gradiant text-white" >Explore More</a>
  </div>
</div>




<div class="position-relative overflow-hidden my-5 py-5 " style="background-color:rgb(240, 240, 247)">
  <div class="container mb-3">
    <div class="row my-5 py-5">
      <div class="col-12 col-lg-6 my-5 mx-auto d-flex flex-column justify-content-center align-items-center animated fadeInLeft">
        <img src="assets\img\Screenshot_2023-03-07_at_4.19.26_PM-removebg-preview.png" style="height:80% ; width:65% ;">
      </div>
      <div class="col-12 col-lg-6 mx-auto px-4 animated fadeInRight">
        <h1 class="display-4 fw-normal" style="font-family: 'Times New Roman';">Our Company</h1><br>
        <p class="lead fw-light">We have a team of professional experts providing better services to the aspiring
          students regarding their study in Abroad and Visa Assistance.
          Our Dedicated team provides counselling as per the requirement of the students and provide better options and
          services for their further future.
        </p>    
         <a routerLink="/about" class="btn btn-info-gradiant text-white" >Read More</a>
      </div>
    </div>
  </div>
</div>


<div class="container mt-5 pt-5">
  <div class="text-center px-2">
    <h1 style="font-family: 'Times New Roman';"> OUR SERVICES</h1>
    <p class="fs-6 mt-3 text-muted py-3 ">At Fenix Immigration Services, we are committed to making your immigration dreams a reality. 
      Our comprehensive services cover visa applications, document assistance, and personalized consultations, ensuring a smooth 
      and successful immigration journey. Trust Fenix Immigration Services to provide reliable guidance and expert support 
      every step of the way</p>
  </div>


  <div class="row animated fadeInDown">
    <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let services of servicesList">
      <div class="card card-1 mb-3 shadow-lg" style=" border-color: rgb(3, 22, 112);">
        <div class="card-body p-4">
          <div class="fw-bold fs-6"> {{services.title}}</div>&nbsp; 
          <p class="card-text">{{services.description}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center my-4 pb-5 pt-3">
    <a routerLink="/services" class="btn btn-info-gradiant text-white" >Know More</a>
  </div>
</div>


<div class="container-fluid position-relative overflow-hidden" style="background-color:rgb(240, 240, 247)">

  <div class="container my-5 py-5">
    <div class="row">

      <div class="col-md-6 sm-6 my-3 animated fadeInLeft">
        <div class="img mx-auto" style="width: 100%; height: 370px; ">
          <img
            src="https://www.insureon.com/-/jssmedia/blog/posts/2019/photo_people-reviewing-graphs-and-books.jpg?h=370&iar=0&w=750&rev=39b8af1ad7674569945f2960be514d1d"
            class="rounded" style="width: 100%; height: 370px;">
        </div>
      </div>
      <div class="col-md-6 sm-6 my-5 mx-auto animated fadeInRight">
        <h2 style="font-family: 'Times New Roman';">FREE CONSULTATION</h2><br>
        <p class="lead fw-light">Fenix offers free consultations to individuals who are looking to retain a visa but have some questions before 
          making a decision. This free case evaluation service allows prospective clients to contact
           us and understand what services we can offer. This is an opportunity for prospective clients to learn about their immigration
            options and how Fenix immigration can help them. </p>
        <a routerLink="/contact" class="btn btn-info-gradiant text-white" >Consult For Free</a>
      </div>
    </div>
  </div>
</div>