<div class="container-fluid m-0 p-0 ">
  <div class="bg-image p-5" style="background-image: url('https://skylighteducation.in/img/home-slider/slider-13.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;">
         <div class="text-center text-white p-lg-5  mx-auto my-5 animated fadeInDown">
           <h1  style="font-family: 'Times New Roman';">ABOUT US</h1> 
        </div>
     </div>
      </div>

        <div class="container my-5 p-3">
        <div class="row">
            <div class="col-md-6 sm-6 my-5 mx-auto animated fadeInLeft">
              <h1 style="font-family: 'Times New Roman';">Fenix Immigration</h1><br>
              <p class="lead fw-light">Fenix Immigration is a global immigration firm that was established in 2010. 
                The company was founded by a team of experienced immigration attorneys who recognized the need for a client-focused, results-driven immigration law firm.      
                The firm offers a wide range of immigration services, including business and investor immigration, family sponsorship, and refugee and asylum applications.</p>
              </div>   
        <div class="col-md-6 sm-6 my-5 animated fadeInRight">
          <div class="img p-2" style="width: 100%; height: 370px; ">
            <img src="https://www.cmr.edu.in/blog/wp-content/uploads/2023/01/Five-Ways-To-Manage-A-College-Project-in-a-Stress-free-Manner.jpg" class="rounded" style="width: 100%; height: 370px;">
          </div>
        </div>
      </div>
      </div>
      
  
      <div class="position-relative overflow-hidden p-md-5 my-md-3 " style="background-color:rgb(240, 240, 247)">
        <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 mt-5 pt-5 flex flex-column justify-content-center align-items-center animated fadeInLeft">
            <img class="img1" src="assets\img\Screenshot_2023-03-07_at_4.19.26_PM-removebg-preview.png" >
          </div>
        <div class="col-lg-6 col-md-12 p-3 animated fadeInRight">
          <h1 class="display-4 fw-normal" style="font-family: 'Times New Roman';">Our Company</h1><br>
          <p class="lead fw-light">With Fenix Immigration Services by your side, you can navigate the complexities of the immigration process with ease. From visa applications to documentation, we ensure a smooth and hassle-free journey towards your educational aspirations. We have a team of professional experts providing better services to the aspiring students regarding their study in Abroad and Visa Assistance. 
            Our Dedicated team provides counselling as per the requirement of the students and provide better options and services for their further future.
          </p><br>
        </div>
      </div>
      </div>
      </div>

        <div class="container my-5 py-5">
          <div class="row">
            <div class="section-head col-sm-12">
              <h4><span>Why Choose</span> Us?</h4>
              <p> We offer personalized guidance, keeping your unique needs in mind. Our experienced consultants will assist you in choosing the right educational institution, guiding you through the application process, and supporting you every step of the way.
              </p>
            </div>
          </div>
            <div class="row mt-4 animated fadeInUp">
            <div class="col-lg-4 col-sm-6">
              <div class="item"> <span class="icon feature_box_col_one"><i class="fa fa-globe"></i></span>
                <h6>TRUTH</h6>
                <p>Fenix Immigration strictly believes in following a transparent methodology wherein clients are updated at each stage of the process of both, either IELTS or Visa.</p>
               </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="item"> <span class="icon feature_box_col_two"><i class="fa fa-anchor"></i></span>
                <h6>TRANSPARENCY</h6>
                <p>Fenix Immigration strictly believes in following a transparent methodology wherein clients are updated at each stage of the process of both, either IELTS or Visa.</p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="item"> <span class="icon feature_box_col_three"><i class="fa fa-hourglass-half"></i></span>
                <h6>EFFICIENCY</h6>
                <p>Fenix Immigration promises efficiency by its highly skilled and well-experienced team members regardless of any complexities in the client’s profile.</p>
              </div>
            </div>
          </div>
        </div>
    

    
    <div class="container-fluid m-0 p-0 ">
    <div class="bg-image p-2" style="background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 10%, transparent)0% 0%, url('https://www.pushhere.com/blog/wp-content/uploads/2022/04/iStock-1165150874-min.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;   background-attachment: fixed;">
      <div class="text-center text-white p-lg-5 mx-auto my-5">
        <h1 class="fw-bold" style="font-family: popins;">Wanna Meet Us <br>Or<br>Book an Appoinment</h1> 
        <a routerLink="/contact" class="btn btn-info-gradiant">Book Now</a> 
      </div>
      </div>
      </div>
      

      <div class="container my-5 py-5">
        <div class="row">
          <div class="col-md-6 sm-6 my-5 animated fadeInLeft">
            <div class="img mx-auto p-2" style="width: 100%; height: 370px; ">
              <img src="https://images.unsplash.com/photo-1551836022-d5d88e9218df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGJvc3N8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" class="rounded" style="width: 100%; height: 370px;">
            </div>
          </div>
            <div class="col-md-6 sm-6 my-5 p-3 animated fadeInRight">
              <h2 style="font-family: 'Times New Roman';">Director's Message</h2><br>
              <p class="lead fw-light">At Fenix Immigration Services, we understand that immigration can be a challenging and stressful process. 
                Our team of experienced professionals is committed to providing you with expert guidance, exceptional customer service, and personalized attention to help you achieve your immigration goals. We are honored to be a part of your journey and look forward to serving you with integrity, efficiency, and compassion.</p>
              </div>   
      </div>
      </div>
