export const SERVICES_COLLECTION = "Services";
export const ADDRESS_COLLECTION = 'Address';
export const QUERIES_COLLECTION = "queries";
export const SOCIAL_COLLECTION ='socialLinks';
export const REVIEWS_COLLECTION = 'Reviews';
export const VISAS_COLLECTION = "Visas";
export const TESTIMONIALS_COLLECTION = 'testimonials';

export const GALLERY_COLLECTION = "Gallery";

export const INSTITUTE_GALLERY_COLLECTION = "Gallery-Institute";
export const RESULT_GALLERY_COLLECTION = "Gallery-Results";
// export const VISA_GALLERY_COLLECTION = "Gallery-Visa";