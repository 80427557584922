<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>
<div *ngIf="!loader"  style="position: fixed; right: 10px; bottom: 10px; z-index: 1;">
  <div class="d-flex flex-row flex-lg-column  rounded" style="background-color: white;">
    <a href="{{ social?.whatsappNumber }}" target="_blank" class="btn mx-1">
      <i class="fa-brands fa-whatsapp text-success fa-xl"></i>
    </a>
    <a href="{{ social?.facebook }}" target="_blank" class="btn mx-1 ">
      <i class="fa-brands fa-facebook text-primary fa-xl"></i>
    </a>
    <!-- <a href="{{ social?.youtube }}" target="_blank" class="btn  mx-1 ">
      <i class="fa-brands fa-youtube text-danger fa-xl"></i>
    </a> -->
  </div>
</div>
