export const environment = {
    firebase: {
        apiKey: "AIzaSyC3z0lDL48pGRDOQRbuBVyUX485KdLmtxw",
        authDomain: "fenix-immigration-services.firebaseapp.com",
        projectId: "fenix-immigration-services",
        storageBucket: "fenix-immigration-services.appspot.com",
        messagingSenderId: "668583289261",
        appId: "1:668583289261:web:6f0d5a2d4e340e6d1a8124",
        measurementId: "G-J5DMWWQXRM"
    }
};
