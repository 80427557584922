<div class="container-fluid p-0 m-0">
  <div class="banner">
    <div class="row justify-content-center mt-3">
      <div class="col-lg-9 col-md-6 align-self-center text-center">
        <!-- <h1 class="title text-white ">VISA</h1> -->
      </div>
    </div>
  </div>
</div>
  
      <div class="container mt-5">
          <div class="text-center">
          <h1 style="font-family: 'Franklin Gothic Medium';">STUDY VISA</h1>
          <h6 class="text-muted"> Choose from vast Countries to settle in Abroad</h6>
      </div>
  
      <div class="row row-cols-1 row-cols-md-3 g-4 mt-5 mb-5 animated fadeInUp">
        <div class="col" *ngFor="let visas of visaList; index as idx">
          <div class="card h-100">
            <img [src]="visas.imageUrl" style="width:100%; height: 270px">
            <div class="card-body">
              <h5 class="card-title">{{visas.title}}</h5>
              <p class="card-text">{{visas.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
