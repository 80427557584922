<div class="container-fluid p-0 m-0">
  <div class="banner">
    <div class="row justify-content-center py-5 my-5">
      <div class="col-12 align-self-center text-center">
        <h1 class="title text-white animated fadeInDown" style="font-family: 'Times New Roman';">CONTACT US</h1>
      </div>
    </div>
  </div>
</div>
  
  <div class="container mt-5"> 
    <div class="text-center mt-5 pt-5">
      <!-- <h1 class="fw-light">Contact Us</h1><br> -->
      <h4 style="font-family: 'Times New Roman';"> Have a Query? Feel Free to contact us</h4>
    </div> 
  </div>

   <div class="container">
    <div class="row my-5 ">

      <div class="col-md-12 col-lg-6 mb-4 animated fadeInLeft">      
        <div class="card" >
          <div class="card-body ">

            <div><i class="bi bi-geo-alt-fill"></i> &nbsp;
            <span>{{mainAddress.address}}</span>&nbsp;
          </div>&nbsp;

          <div><i class="bi bi-envelope-fill"></i> &nbsp;
           <a class=" fs-6" [href]="'mailto'+mainAddress.mail || ''" style="text-decoration: none;"><span>{{mainAddress.mail}}</span></a>&nbsp;
           </div>&nbsp;

           <div><i class="bi bi-telephone-fill"></i> &nbsp;
           <a class=" fs-6" [href]="'tel:+919009078178'" style="text-decoration: none;"><span>+919009078178, </span></a><a class=" fs-6" [href]="'tel:+919009067367'" style="text-decoration: none;"><span> +919009067367</span></a>&nbsp; 
          </div>&nbsp;

            <iframe [src]="mapLink" allowfullscreen="" style="width:100%; height: 330px;; border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>

          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-6  mb-4 animated fadeInRight">   
       <div class="card " >
        <div class="card-body">
            <form [formGroup]="queryForm" (ngSubmit)="onSubmit($event)">
              <div class="form-row">
                <div class="col-md-12 mb-4 mt-3">
                  <label for="name">Name</label>&nbsp;
                  <input type="name" class="form-control" id="name" formControlName="name">
                </div>
                <div class="col-md-12 mb-4">
                  <label for="email">Email</label><br>
                  <input type="email" class="form-control" id="email" formControlName="email">
                </div>
                <div class="col-md-12 mb-4">
                  <label for="phone">Contact No.</label>
                  <input type="phone" class="form-control" id="phone" formControlName="phone">
                </div>
                <div class="col-md-12 mb-4">
                  <label for="query">Query</label><br>
                  <textarea class="form-control" id="query" rows="4" formControlName="query"></textarea>
                </div>
              </div>
              <!-- <div class="alert alert-success" role="alert" *ngIf="formSubmitted">
                <h4 class="alert-heading">Form Submitted!</h4>
                <p>We have received your query and will get back to you soon.</p>
              </div> -->
              <div class=" mt-5 text-end ">
                <button type="submit" class="btn btn-secondary" [disabled]="formSubmitted">Submit</button>
              </div>
            </form>
         </div>       
        </div>
      </div>
     </div>
    </div>