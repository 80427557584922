<div class="container-fluid p-0 m-0">
  <div class="banner">
    <div class="row justify-content-center">
      <div class="col-lg-9 col-md-6 align-self-center text-center">
        <!-- <h1 class="title text-white ">VISA</h1> -->
      </div>
    </div>
  </div>
</div>


<div class="container mt-5">
  <div class=" text-center my-5">
    <h2 class="h1 text-center" style="font-family: 'Times New Roman';">Services We Provide</h2>
    <p class="lead fw-light mt-4">Fenix offers free consultations to individuals who are looking to retain a visa but have some questions before 
      making a decision. This free case evaluation service allows prospective clients to contact
       us and understand what services we can offer. This is an opportunity for prospective clients to learn about their immigration
        options and how Fenix immigration can help them. </p>
  </div>
</div>

<div class="container pb-5">
  <div class="row animated fadeInUp">
    <div class="col-12 col-sm-6 col-lg-4 mb-3" *ngFor="let services of servicesList">
      <div class="card card-1 mb-3 shadow-lg" style=" border-color: rgb(3, 22, 112);">
        <div class="card-body p-4">
          <div class="fw-bold fs-6"> {{services.title}}</div>&nbsp; 
          <p class="card-text">{{services.description}}</p>
        </div>
      </div>
    </div>
  </div>

</div>


<div class="container mt-5 pt-5">
  <div class="row">
    <h1 class="text-center" >TESTIMONIALS</h1>
  </div>
  </div>

<div class="container mt-2 my-4 mb-5 ">
  <div class="row  ">
    <div class="slider col-12 text-center mb-3">
      <ngb-carousel [interval]="4500" [keyboard]="true" [wrap]="true" [animation]="true" [showNavigationIndicators]="false" [showNavigationArrows]="true">
        <ng-template ngbSlide *ngFor="let testimonial of testimonialList" class="rounded">
          <div class="rounded">
            <img loading="eager" class="rounded-circle shadow-1-strong my-4" [src]="testimonial.imageUrl" style="width: 180px; height: 180px;">
            <div class=" row d-flex justify-content-center ">
              <div class="col-lg-3" style="width: 100%">
                <h5 class="my-3">{{testimonial.title}}</h5>
                <p class="text-muted">
                  <i class="fas fa-quote-left pe-2" style="font-size: 20px;"></i>
                  "{{testimonial.description}}"
                </p>
              </div>
            </div>
          </div> 
        </ng-template>
       </ngb-carousel>
    </div>
  </div>
</div>

  <!-- <div class="container my-4 ">
     <ngb-carousel [interval]="3500" [keyboard]="true" [wrap]="true" [animation]="true" [showNavigationIndicators]="true" [showNavigationArrows]="true">
       <ng-template ngbSlide *ngFor="let testimonial of testimonialList" class="rounded" >
         <div class="row d-flex justify-content-center">
           <div class="slider col-12 text-center mb-3">
              <div class="col-lg-4" style="width: 100%">

                <div class="card text-start">
                  <img class="card-img-top" [src]="testimonial.imageUrl" style="width: 150px;" alt="Title">
                  <div class="card-body">
                    <h4 class="card-title">Title</h4>
                    <p class="card-text">Body</p>
                  </div>
                </div> -->
               <!-- <div class="card" style="border:none; background-color: transparent;">
                 <div class="card-body">
               <img loading="eager" class="rounded-circle shadow-1-strong mb-4"
               [src]="testimonial.imageUrl" alt="avatar"
                 style="width: 150px;" />
               <h5 class="mb-3">{{testimonial.title}}</h5>
               <p class="text-muted">
                 <i class="fas fa-quote-left pe-2"></i>
                 "{{testimonial.description}}"
               </p>
             </div>
             </div> -->
           <!-- </div>
         </div>
       </div>
         </ng-template>
        </ngb-carousel>
     </div> -->