<footer class="footer py-5" style="background-color: rgb(2, 9, 44);">
  <div class="container-fluid">
    <div class="row my-2">
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column justify-content-right align-items-right" style="height:100%; padding:inherit" >
          <img src="assets\img\fenixLogoWithTextInWhite.png" width="150" height="50">
        <h4 class="text-white">Fenix Immigration Services</h4>
        <p class="text-white">You dream, We accomplish</p>
        <!-- <p class="d-block mb-3 text-white">Providing personalized solutions to individuals
          and families seeking to immigrate and get visa assistance.</p> -->
        </div>
        </div>
        
      <div class="col-12 col-md-6 text-white">
        <div class="d-flex flex-column justify-content-end align-items-end" style="height: 100%; padding: inherit;">
          <h5>Official Info</h5>
        <ul class="list-unstyled text-small text-end">
          <li>Call us:<a class="link-secondary text-white" style="text-decoration:none;" href="{{'tel:+919009078178'}}"> +91 9009078178, </a><a class="link-secondary text-white" style="text-decoration:none;" href="{{'tel:+919009067367'}}"> +91 9009067367</a></li>
          <li>Email:<a class="link-secondary text-white" style="text-decoration:none;" href="{{'mailto:'+addressInfo.mail}}"> {{addressInfo.mail || ""}}</a></li>
          <li>Address:<span class="link-secondary text-white" style="text-decoration:none;"> {{addressInfo.address}} </span></li>
        </ul>
        </div>
        
      </div>
    </div>
  </div>
</footer>